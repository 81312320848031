export enum CMajNote {
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  A = "A",
  B = "B",
}

export enum Accidental {
  DoubleFlat = "𝄫",
  Flat = "♭",
  None = "",
  Sharp = "♯",
  DoubleSharp = "𝄪",
}

export type TimeSignature = {
  top: number;
  bottom: number;
};

export type Bar = {
  id: string;
  index: number;
  timeSignature: TimeSignature;
};

export type BarData = { [barId: string]: Bar };

export type Chord = {
  id: string;
  note: CMajNote;
  accidental: Accidental;
  quality: string;
  octave: number;
  barId: string;
  offset: number;
};

export type ChordData = { [chordId: string]: Chord };

export enum EventType {
  Chord,
  Metronome,
  Both,
}

export type SongData = {
  name: string;
  tempo: number;
  barData: BarData;
  chordData: ChordData;
};
