import { createPopper } from "@popperjs/core";
import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from "lz-string";
import metronomeFilePath from "./assets/sound/metronome.mp3";
// import { data } from "./examples/best-part";
import {
  Accidental,
  Bar,
  BarData,
  Chord,
  ChordData,
  CMajNote,
  EventType,
  SongData,
  TimeSignature,
} from "./types";

const MAX_VOICES = 12;
const DEFAULT_GAIN = 1 / MAX_VOICES;

const MAJOR = {
  longName: "major",
  shortName: "",
  notes: [0, 4, 7],
};

const chordQualities = [
  MAJOR,
  {
    longName: "minor",
    shortName: "m",
    notes: [0, 3, 7],
  },
  {
    longName: "augmented",
    shortName: "aug",
    notes: [0, 4, 8],
  },
  {
    longName: "diminished",
    shortName: "dim",
    notes: [0, 3, 6],
  },
  {
    longName: "suspended fourth",
    shortName: "sus4",
    notes: [0, 5, 7],
  },
  {
    longName: "suspended second",
    shortName: "sus2",
    notes: [0, 2, 7],
  },
  {
    longName: "dominant seventh",
    shortName: "7",
    notes: [0, 4, 7, 10],
  },
  {
    longName: "major seventh",
    shortName: "maj7",
    notes: [0, 4, 7, 11],
  },
  {
    longName: "minor seventh",
    shortName: "m7",
    notes: [0, 3, 7, 10],
  },
  {
    longName: "minor ninth",
    shortName: "m9",
    notes: [0, 3, 7, 10, 14],
  },
  {
    longName: "minor eleventh",
    shortName: "m11",
    notes: [0, 3, 7, 10, 14, 17],
  },
  {
    longName: "dominant seventh flat five",
    shortName: "7♭5",
    notes: [0, 4, 6, 10],
  },
  {
    longName: "minor seventh flat five",
    shortName: "m7♭5",
    notes: [0, 3, 6, 10],
  },
  {
    longName: "diminished major seventh",
    shortName: "oM7",
    notes: [0, 3, 6, 11],
  },
  {
    longName: "minor major seventh",
    shortName: "mM7",
    notes: [0, 3, 7, 11],
  },
];

const OFFSET_FROM_A = {
  [CMajNote.C]: -9,
  [CMajNote.D]: -7,
  [CMajNote.E]: -5,
  [CMajNote.F]: -4,
  [CMajNote.G]: -2,
  [CMajNote.A]: 0,
  [CMajNote.B]: 2,
};
const ACCIDENTAL_TO_OFFSET = {
  [Accidental.DoubleFlat]: -2,
  [Accidental.Flat]: -1,
  [Accidental.None]: 0,
  [Accidental.Sharp]: 1,
  [Accidental.DoubleSharp]: 2,
};

let barData: BarData = {};
let chordData: ChordData = {};

let audioCtx: AudioContext | undefined;
let vca: GainNode;
const oscillators: OscillatorNode[] = [];
const sweepEnvs: GainNode[] = [];

let name = "";
let tempo = 120;
let barsPerLine = 4;
const lookahead = 25.0;
const scheduleAheadTime = 0.1;

let nextEventTime: number;

let nextEventType: EventType;
let timerId: number;
let nextChordId: string;

let metronomeSample: AudioBuffer;

const getNewBarId = (): string => {
  const idInts = Object.keys(barData).map((k) => parseInt(k));
  if (idInts.length === 0) {
    return "0";
  } else {
    return String(Math.max(...idInts) + 1);
  }
};

const getNewChordId = (): string => {
  const idInts = Object.keys(chordData).map((k) => parseInt(k));
  if (idInts.length === 0) {
    return "0";
  } else {
    return String(Math.max(...idInts) + 1);
  }
};

const getMaxBarIndex = () => {
  return Math.max(...Object.values(barData).map((bar) => bar.index));
};

const addBar = (newBar: Bar) => {
  const maxBarIndex = getMaxBarIndex();
  const numBars = Object.values(barData).length;
  if (newBar.index > maxBarIndex + 1 && numBars > 0) {
    throw new Error("Adding a bar after max index");
  } else if (newBar.index < 0) {
    throw new Error("Adding a bar with index < 0");
  } else {
    // Update the indexes of all bars > the new bar's index
    for (const existingBar of Object.values(barData)) {
      if (existingBar.index >= newBar.index) {
        barData[existingBar.id].index = existingBar.index + 1;
      }
    }
  }
  barData[newBar.id] = newBar;
};

const updateBar = (bar: { id: string } & Partial<Bar>) => {
  // if (bar.index && bar.index !== barData[bar.id].index) {
  // }
  barData[bar.id] = { ...barData[bar.id], ...bar };
};

const removeBar = (id: string) => {
  const removedIndex = barData[id].index;

  // Remove all dangling chords
  for (const chord of Object.values(chordData)) {
    if (chord.barId === id) {
      delete chordData[chord.id];
    }
  }

  // Remove the bar
  delete barData[id];

  // Update all indexes above the index of the removed bar's index
  for (const bar of Object.values(barData)) {
    if (bar.index > removedIndex) {
      bar.index = bar.index - 1;
    }
  }
};

const addChord = (newChord: Chord) => {
  const chords = Object.values(chordData);
  const isConflict = chords.some(
    (c) => c.barId === newChord.barId && c.offset === newChord.offset
  );
  if (isConflict) {
    throw new Error(
      "Can't add chord: conflict with existing chord in bar at offset"
    );
  }
  chordData[newChord.id] = newChord;
};

const updateChord = (chord: { id: string } & Partial<Chord>) => {
  chordData[chord.id] = { ...chordData[chord.id], ...chord };
};

const removeChord = () => {};

const getFile = async (audioCtx: AudioContext, filePath: string) => {
  const response = await fetch(filePath);
  const arrayBuffer = await response.arrayBuffer();

  // To keep iOS happy we have to use a callback instead of promise based syntax here
  const audioBuffer: AudioBuffer = await new Promise((resolve, reject) => {
    audioCtx.decodeAudioData(arrayBuffer, (audioBuffer) => {
      resolve(audioBuffer);
    });
  });

  return audioBuffer;
};

const setupMetronomeSample = async () => {
  const filePath = metronomeFilePath;
  metronomeSample = await getFile(audioCtx, filePath);
};

const playSample = (
  audioCtx: AudioContext,
  audioBuffer: AudioBuffer,
  time: number
) => {
  const sampleSource = audioCtx.createBufferSource();
  sampleSource.buffer = audioBuffer;
  const gainVca = audioCtx.createGain();
  gainVca.gain.value = 0.5;
  gainVca.connect(audioCtx.destination);
  sampleSource.connect(gainVca);
  sampleSource.start(time);
};

const setTempo = (value: number) => {
  tempo = value;
};

const setBarsPerLine = (value: number) => {
  barsPerLine = value;
};

const sortOffset = (a: { offset: number }, b: { offset: number }) => {
  return a.offset < b.offset ? -1 : 1;
};

const getChordsByBarId = (barId: string) => {
  return Object.values(chordData)
    .filter((c) => c.barId === barId)
    .sort(sortOffset);
};

const removeTooltips = () => {
  const tooltips = document.getElementsByClassName(
    "tooltip"
  ) as HTMLCollectionOf<HTMLDivElement>;

  for (const tooltip of Array.from(tooltips)) {
    tooltip.parentElement.removeChild(tooltip);
  }
};

const chordIdAttr = (id: string): string => `chord-${id}`;
const barIdAttr = (id: string): string => `bar-${id}`;

const draw = () => {
  const container = document.getElementById("progression-container");
  container.innerHTML = ""; // Clear

  for (const bar of getSortedBars()) {
    const barContainer = document.createElement("div");
    barContainer.className = "bar-container";
    barContainer.id = barIdAttr(bar.id);

    const barInnerDiv = document.createElement("div");
    barInnerDiv.className = "bar-inner-div";

    let isTimeSigVisible = true;
    // if (bar.index === 0) {
    //   isTimeSigVisible = true;
    // }

    if (isTimeSigVisible) {
      const timeSig = document.createElement("div");
      timeSig.className = "bar-time-sig";
      const timeSigTop = document.createElement("div");
      timeSigTop.innerText = String(bar.timeSignature.top);
      timeSig.appendChild(timeSigTop);

      const timeSigBottom = document.createElement("div");
      timeSigBottom.innerText = String(bar.timeSignature.bottom);
      timeSig.appendChild(timeSigBottom);

      timeSig.addEventListener("click", (e) => {
        e.stopPropagation();
        removeTooltips();

        const temptimeSig: TimeSignature = { ...bar.timeSignature };

        const tooltip = document.createElement("div");
        tooltip.className = "tooltip";
        tooltip.addEventListener("click", (e) => {
          e.stopPropagation();
        });

        const topSelect = document.createElement("select");
        topSelect.title = "Time signature top";
        const tops = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
        for (const top of tops) {
          const option = document.createElement("option");
          option.value = String(top);
          if (top === bar.timeSignature.top) {
            option.selected = true;
          }
          option.innerText = String(top);
          topSelect.appendChild(option);
        }

        topSelect.addEventListener("change", () => {
          const value = topSelect.value;
          temptimeSig.top = Number(value);
        });

        tooltip.appendChild(topSelect);

        const bottomSelect = document.createElement("select");
        bottomSelect.title = "Time signature bottom";
        const bottoms = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
        for (const bottom of bottoms) {
          const option = document.createElement("option");
          option.value = String(bottom);
          if (bottom === bar.timeSignature.bottom) {
            option.selected = true;
          }
          option.innerText = String(bottom);
          bottomSelect.appendChild(option);
        }

        bottomSelect.addEventListener("change", () => {
          const value = bottomSelect.value;
          temptimeSig.bottom = Number(value);
        });

        tooltip.appendChild(bottomSelect);

        const saveAllButton = document.createElement("button");
        saveAllButton.innerText = "Save for all bars";
        saveAllButton.addEventListener("click", () => {
          for (const otherbar of Object.values(barData)) {
            otherbar.timeSignature = { ...temptimeSig };
          }
          removeTooltips();
          draw();
        });
        tooltip.appendChild(saveAllButton);

        const saveAfterButton = document.createElement("button");
        saveAfterButton.innerText = "Save for this bar and all bars after";
        saveAfterButton.addEventListener("click", () => {
          updateBar({ id: bar.id, timeSignature: { ...temptimeSig } });
          for (const otherbar of Object.values(barData)) {
            if (otherbar.index > bar.index) {
              otherbar.timeSignature = { ...temptimeSig };
            }
          }
          removeTooltips();
          draw();
        });
        tooltip.appendChild(saveAfterButton);

        const saveThisBarButton = document.createElement("button");
        saveThisBarButton.innerText = "Save for only this bar";
        saveThisBarButton.addEventListener("click", () => {
          updateBar({ id: bar.id, timeSignature: { ...temptimeSig } });
          removeTooltips();
          draw();
        });
        tooltip.appendChild(saveThisBarButton);

        const arrow = document.createElement("div");
        arrow.className = "arrow";
        tooltip.append(arrow);

        document.body.appendChild(tooltip);

        createPopper(timeSig, tooltip, {
          placement: "bottom-start",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 16],
              },
            },
          ],
        });
      });

      barInnerDiv.appendChild(timeSig);
    }

    const chordsInBar = getChordsByBarId(bar.id);

    for (const chord of chordsInBar) {
      const chordContainer = document.createElement("div");
      chordContainer.id = chordIdAttr(chord.id);

      chordContainer.appendChild(
        document.createTextNode(
          chord["note"] + chord["accidental"] + chord["quality"]
        )
      );
      chordContainer.className = "chord-container";

      chordContainer.addEventListener("click", (e) => {
        e.stopPropagation();
        removeTooltips();

        const tooltip = document.createElement("div");
        tooltip.className = "tooltip";
        tooltip.addEventListener("click", (e) => {
          e.stopPropagation();
        });

        const noteSelect = document.createElement("select");
        noteSelect.title = "Note";
        const notes = Object.keys(CMajNote);
        for (const note of notes) {
          const option = document.createElement("option");
          option.value = note;
          if (note === chord.note) {
            option.selected = true;
          }
          option.innerText = note;
          noteSelect.appendChild(option);
        }

        noteSelect.addEventListener("change", () => {
          const nextNote = noteSelect.value as CMajNote;
          updateChord({ id: chord.id, note: nextNote });
          draw();
        });
        tooltip.appendChild(noteSelect);

        const accidentalSelect = document.createElement("select");
        accidentalSelect.title = "Accidental";
        const accidentals = Object.values(Accidental);
        for (const accidental of accidentals) {
          const option = document.createElement("option");
          option.value = accidental;
          if (accidental === chord.accidental) {
            option.selected = true;
          }
          option.innerText = accidental;
          accidentalSelect.appendChild(option);
        }

        accidentalSelect.addEventListener("change", () => {
          const nextAccidental = accidentalSelect.value as Accidental;
          updateChord({ id: chord.id, accidental: nextAccidental });
          draw();
        });
        tooltip.appendChild(accidentalSelect);

        const qualitySelect = document.createElement("select");
        qualitySelect.title = "Quality";
        const qualitys = chordQualities.map((q) => q.shortName);
        for (const quality of qualitys) {
          const option = document.createElement("option");
          option.value = quality;
          if (quality === chord.quality) {
            option.selected = true;
          }
          option.innerText = quality;
          qualitySelect.appendChild(option);
        }

        qualitySelect.addEventListener("change", () => {
          const nextAccidental = qualitySelect.value;
          updateChord({ id: chord.id, quality: nextAccidental });
          draw();
        });
        tooltip.appendChild(qualitySelect);

        const octaveSelect = document.createElement("select");
        octaveSelect.title = "Octave";
        const octaves = [3, 4, 5];
        for (const octave of octaves) {
          const option = document.createElement("option");
          option.value = String(octave);
          if (octave === chord.octave) {
            option.selected = true;
          }
          option.innerText = String(octave);
          octaveSelect.appendChild(option);
        }

        octaveSelect.addEventListener("change", () => {
          const nextOctave = parseInt(octaveSelect.value);
          updateChord({ id: chord.id, octave: nextOctave });
          draw();
        });
        tooltip.appendChild(octaveSelect);

        const offsetInput = document.createElement("input");
        offsetInput.type = "number";
        offsetInput.max = "100";
        offsetInput.min = "0";
        offsetInput.value = String(chord.offset * 100);
        offsetInput.title = "Offset";

        offsetInput.addEventListener("change", (e) => {
          const value = Number((e.target as HTMLInputElement).value) / 100;
          if (!isNaN(value)) {
            updateChord({ id: chord.id, offset: value });
            draw();
          }
        });
        tooltip.appendChild(offsetInput);

        const arrow = document.createElement("div");
        arrow.className = "arrow";
        tooltip.append(arrow);

        document.body.appendChild(tooltip);

        createPopper(chordContainer, tooltip, {
          placement: "bottom-start",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 16],
              },
            },
          ],
        });
      });

      // Calculate the flex basis for the chord
      // It's equal to the space this chord needs to take up in the bar
      // Simple case of 1 chord in the bar = 100%
      // 2 chords with the second starting at 0.5 = 50% each
      // Get offset of next chord in bar (if one exists) else 1

      const currentchordIndex = chordsInBar.findIndex((c) => c.id === chord.id);
      const nextIndex = currentchordIndex + 1;
      const nextchordOffset =
        nextIndex === chordsInBar.length ? 1 : chordsInBar[nextIndex].offset;

      // (1 - this chords offset - (1 - next chord offset)) * 100
      const flexBasis = (1 - chord.offset - (1 - nextchordOffset)) * 100;
      chordContainer.style.flexBasis = `${flexBasis}%`;
      barInnerDiv.appendChild(chordContainer);

      const barMenuButton = document.createElement("button");
      barMenuButton.className = "bar-menu-button";
      barMenuButton.innerHTML = `<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512' height='16' width='16'><title>Chevron Down</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 184l144 144 144-144'/></svg>`;

      barMenuButton.addEventListener("click", (e) => {
        e.stopPropagation();
        removeTooltips();

        const tooltip = document.createElement("div");
        tooltip.className = "tooltip";
        tooltip.addEventListener("click", (e) => {
          e.stopPropagation();
        });

        const arrow = document.createElement("div");
        arrow.className = "arrow";
        tooltip.append(arrow);

        const removeBarButton = document.createElement("button");
        removeBarButton.innerText = "Delete";
        removeBarButton.addEventListener("click", () => {
          removeBar(bar.id);
          removeTooltips();
          draw();
        });
        tooltip.appendChild(removeBarButton);

        document.body.appendChild(tooltip);

        createPopper(barMenuButton, tooltip, {
          placement: "bottom-start",
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 16],
              },
            },
          ],
        });
      });

      barInnerDiv.appendChild(barMenuButton);

      barContainer.appendChild(barInnerDiv);
    }

    container.appendChild(barContainer);
  }
};

const playChord = (notes: number[], time: number) => {
  const sweepEnv = audioCtx.createGain();
  sweepEnv.gain.value = 1;
  sweepEnv.connect(vca);
  sweepEnvs.push(sweepEnv);

  for (const freq of notes) {
    const oscillator = audioCtx.createOscillator();
    oscillator.type = "sine";
    oscillator.frequency.setValueAtTime(freq, 0);
    oscillator.connect(sweepEnv);
    oscillators.push(oscillator);
    oscillator.start(time);
  }
};

const stopOscillators = (time: number) => {
  while (sweepEnvs.length > 0) {
    sweepEnvs.pop();
  }

  while (oscillators.length > 0) {
    const oscillator = oscillators.pop();
    oscillator.stop(time);
  }
};

const constructChord = (
  note: CMajNote,
  accidental: Accidental,
  qualityShortName: string,
  octave: number,
  aHertz = 440
) => {
  const chordQuality = chordQualities.find(
    (q) => q.shortName === qualityShortName
  );

  // Equal temperament
  const interval = Math.pow(2, 1 / 12);

  // Calculate offset from A for the root of the chord
  const rootOffsetFromA =
    (octave - 4) * 12 + OFFSET_FROM_A[note] + ACCIDENTAL_TO_OFFSET[accidental];

  const notes = chordQuality.notes.map((n) => {
    return aHertz * Math.pow(interval, rootOffsetFromA + n);
  });

  return notes;
};

const barLengthSeconds = (
  tempo: number,
  timeSigTop: number,
  timeSigBottom: number
) => {
  // BPM is quarter notes per min: QNPM
  // Length of QNPM in seconds = 60 / QNPM
  const quarterNoteLength = 60 / tempo;
  // Ratio of quarter notes to time sig bottom = 4 / timeSigBottom
  // Length of bar in seonds = Length of QNPM in seconds * Ratio of quarter notes to time sig bottom * timeSigTop
  const lengthOfBar = quarterNoteLength * (4 / timeSigBottom) * timeSigTop;
  return lengthOfBar;
};

const sortIndex = (a: { index: number }, b: { index: number }) => {
  return a.index < b.index ? -1 : 1;
};

const getSortedBars = () => {
  return Object.values(barData).sort(sortIndex);
};

const getFirstBarId = () => {
  const bars = getSortedBars();
  if (bars.length > 0) {
    return bars[0].id;
  } else {
    return null;
  }
};

const getFirstChordId = () => {
  const firstBarId = getFirstBarId();
  if (firstBarId !== null) {
    let checkBarId = firstBarId;
    // Check for chords in this bar
    while (true) {
      const chords = getChordsByBarId(checkBarId);
      if (chords.length > 0) {
        return chords[0].id;
      } else {
        checkBarId = getNextBarId(checkBarId);
      }
    }
  }
};

const getNextBarId = (currentBarId: string) => {
  const currentBarIndex = barData[currentBarId].index;
  const bars = getSortedBars();
  // Wrap to the first bar
  if (currentBarIndex + 1 == bars.length) {
    return getFirstBarId();
  } else {
    return bars[currentBarIndex + 1].id;
  }
};

const getNextChordId = (currentChordId: string) => {
  const currentChord = chordData[currentChordId];
  // Look for more chords in the bar
  const currentBarId = currentChord.barId;
  const currentBarChords = getChordsByBarId(currentBarId).filter(
    (c) => c.offset > currentChord.offset
  );
  if (currentBarChords.length > 0) {
    return currentBarChords[0].id;
  } else {
    let checkBarId = currentBarId;
    while (true) {
      // Get the next bar (or loop back to first bar)
      const nextBarId = getNextBarId(checkBarId);
      const nextBarChords = getChordsByBarId(nextBarId);
      if (nextBarChords.length > 0) {
        return nextBarChords[0].id;
      } else {
        checkBarId = nextBarId;
      }
    }
  }
};

const getSecondsDelta = (chordAId: string, chordBId: string) => {
  const currentChord = chordData[chordAId];
  const currentBarId = currentChord.barId;
  const nextChord = chordData[chordBId];
  const nextBarId = nextChord.barId;
  const nextBar = barData[nextBarId];
  const currentBar = barData[currentBarId];

  const currentBarLength = barLengthSeconds(
    tempo,
    currentBar.timeSignature.top,
    currentBar.timeSignature.bottom
  );

  if (currentBarId === nextBarId) {
    if (chordAId === chordBId) {
      return currentBarLength;
    } else {
      const timeBeforeInNextChord = currentBarLength * nextChord.offset;
      return timeBeforeInNextChord;
    }
  } else {
    // The next chord is in a different bar
    const nextBarLength = barLengthSeconds(
      tempo,
      nextBar.timeSignature.top,
      nextBar.timeSignature.bottom
    );
    const secondsLeftInBar = currentBarLength * (1 - currentChord.offset);
    const timeBeforeInNextChordBar = nextBarLength * nextChord.offset;
    return secondsLeftInBar + timeBeforeInNextChordBar;
  }
};

const highlightBar = (barId: string) => {
  const barContainers = document.getElementsByClassName(
    "bar-container"
  ) as HTMLCollectionOf<HTMLDivElement>;

  for (const barContainer of Array.from(barContainers)) {
    if (barContainer.id === barIdAttr(barId)) {
      barContainer.style.boxShadow = "rgb(255 255 255) 0px 0px 8px";
    } else {
      barContainer.style.boxShadow = "none";
    }
  }
};

const highlightChord = (chordId: string) => {
  const chordContainers = document.getElementsByClassName(
    "chord-container"
  ) as HTMLCollectionOf<HTMLDivElement>;
  for (const chordContainer of Array.from(chordContainers)) {
    if (chordContainer.id === chordIdAttr(chordId)) {
      chordContainer.style.textShadow = "rgb(0 0 0) 0px 0px 0.5px";
    } else {
      chordContainer.style.textShadow = "none";
    }
  }
};

let nextChordTime: number;
let nextBeatTime: number;

const scheduler = () => {
  while (nextEventTime < audioCtx.currentTime + scheduleAheadTime) {
    // NB. Keep metronome code before chord code as nextChordId changes when a chord is scheduled to start
    // Schedule start of metronome note if need be
    if ([EventType.Metronome, EventType.Both].includes(nextEventType)) {
      playSample(audioCtx, metronomeSample, nextEventTime);

      // Calculate next metromome time
      // Get the next beat based on the time signature of the bar
      // Use the bottom of the time signaure as the subdevision
      // Get bar lenth in seconds. Divide by time sig top to get the interval between metronome clicks for the curernt bar

      const currentBarId = chordData[nextChordId].barId;
      const currentBar = barData[currentBarId];
      const delta =
        barLengthSeconds(
          tempo,
          currentBar.timeSignature.top,
          currentBar.timeSignature.bottom
        ) / currentBar.timeSignature.top;
      nextBeatTime = nextEventTime + delta;
    }

    // Schedule start of chord notes if need be
    if ([EventType.Chord, EventType.Both].includes(nextEventType)) {
      const activeChordId = nextChordId;
      const activeBarId = chordData[activeChordId].barId;

      stopOscillators(nextEventTime);

      // Play all relevant notes on the oscillators
      const chord = chordData[nextChordId];

      const notes = constructChord(
        chord.note,
        chord.accidental,
        chord.quality,
        chord.octave
      );

      playChord(notes, nextEventTime);

      // Calculate next chord time
      const prevChordId = nextChordId;
      nextChordId = getNextChordId(prevChordId);
      const secondsDelta = getSecondsDelta(prevChordId, nextChordId);
      nextChordTime = nextEventTime + secondsDelta;
    }

    const chordMetronomeDelta = Math.abs(nextChordTime - nextBeatTime);

    if (chordMetronomeDelta < 0.001) {
      // Assume the events are at the same time (beat)
      nextEventType = EventType.Both;
      nextEventTime = nextChordTime;
    } else {
      if (nextChordTime < nextBeatTime) {
        nextEventType = EventType.Chord;
        nextEventTime = nextChordTime;
      } else {
        nextEventType = EventType.Metronome;
        nextEventTime = nextBeatTime;
      }
    }
  }

  timerId = window.setTimeout(scheduler, lookahead);
};

try {
  // @ts-ignore
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  audioCtx = new AudioContext();
} catch (e) {
  document.body.innerText = e.stack;
}

const start = async () => {
  document.getElementById("start").style.display = "none";
  document.getElementById("stop").style.display = "flex";

  nextEventTime = audioCtx.currentTime + 0.1;
  nextEventType = EventType.Both;
  nextChordId = getFirstChordId();

  scheduler();
};

const resumeAudioContext = async () => {
  if (audioCtx.state !== "running") {
    await audioCtx.resume();
  }
};

const stop = () => {
  document.getElementById("stop").style.display = "none";
  document.getElementById("start").style.display = "flex";

  highlightBar(null);
  highlightChord(null);

  window.clearTimeout(timerId);
  stopOscillators(audioCtx.currentTime);
};

const compressData = (songData: SongData): string => {
  const stringified = JSON.stringify(songData);
  const compressed = compressToEncodedURIComponent(stringified);
  return compressed;
};

const updateUrl = (songData: string) => {
  window.location.search = `d=${songData}`;
};

const decompressData = (songDataStr: string): SongData => {
  const songData = JSON.parse(
    decompressFromEncodedURIComponent(songDataStr)
  ) as SongData;
  return songData;
};

const loadData = (songData: SongData) => {
  name = songData.name;
  tempo = songData.tempo;
  barData = songData.barData;
  chordData = songData.chordData;
};

const startAnimationLoop = () => {
  // if (nextChordId) {
  //   const barId = chordData[nextChordId].barId;
  //   highlightBar(barId);
  //   highlightChord(nextChordId);
  // }
  // requestAnimationFrame(startAnimationLoop);
};

document.body.onload = onload = async () => {
  const nameHeader = document.getElementById("name");
  const nameInput = document.getElementById("name-input") as HTMLInputElement;

  document.querySelector("body").addEventListener("click", () => {
    removeTooltips();
    if (name) {
      nameInput.style.display = "none";
      nameHeader.style.display = "block";
    }
  });

  document.getElementById("start").addEventListener("click", async () => {
    await resumeAudioContext();
    await start();
  });

  document.getElementById("stop").addEventListener("click", () => {
    stop();
  });

  const tempoInput = document.getElementById("tempo-input") as HTMLInputElement;
  tempoInput.addEventListener("change", (e) => {
    setTempo(parseInt((e.currentTarget as HTMLInputElement).value));
  });
  tempoInput.value = String(tempo);

  document.getElementById("addBar").addEventListener("click", () => {
    const barId = getNewBarId();
    const index = Object.values(barData).length;
    const timeSignature = { top: 4, bottom: 4 };
    if (index > 0) {
      const prevBarTimeSignature = Object.values(barData).find(
        (bar) => bar.index === index - 1
      );
      timeSignature.top = prevBarTimeSignature.timeSignature.top;
      timeSignature.bottom = prevBarTimeSignature.timeSignature.bottom;
    }
    addBar({ id: barId, index, timeSignature });
    const chordId = getNewChordId();
    addChord({
      accidental: Accidental.None,
      barId,
      id: chordId,
      note: CMajNote.C,
      octave: 4,
      offset: 0,
      quality: "",
    });
    draw();
  });

  // Get data from URL if available
  var url = new URL(window.location.href);
  const dataCompressed = url.searchParams.get("d");

  if (dataCompressed) {
    const songData = decompressData(dataCompressed);
    loadData(songData);

    history.pushState({}, null, window.location.origin);

    if (name) {
      nameHeader.innerText = name;
      nameInput.value = name;
      nameHeader.style.display = "block";
    } else {
      nameInput.style.display = "block";
    }
  } else {
    // Setup a default bar and chord
    const barId = getNewBarId();
    addBar({ id: barId, index: 0, timeSignature: { top: 4, bottom: 4 } });
    addChord({
      accidental: Accidental.None,
      barId,
      id: getNewChordId(),
      note: CMajNote.C,
      octave: 4,
      offset: 0,
      quality: "",
    });

    nameInput.innerText = name;
    nameInput.style.display = "block";
  }

  nameInput.addEventListener("change", (e) => {
    const value = (e.target as HTMLInputElement).value;
    name = value;
    nameHeader.innerText = value;
    if (value) {
      nameInput.style.display = "none";
      nameHeader.style.display = "block";
    }
  });

  nameInput.addEventListener("click", (e) => {
    e.stopPropagation();
  });

  nameHeader.addEventListener("click", (e) => {
    e.stopPropagation();
    nameHeader.style.display = "none";
    nameInput.style.display = "block";
  });

  document.getElementById("share").addEventListener("click", async () => {
    const songData: SongData = {
      barData,
      chordData,
      tempo,
      name,
    };
    const compressed = compressData(songData);
    const url = `${window.location.origin}?d=${compressed}`;
    await window.navigator.clipboard.writeText(url);
    alert("Sharable link copied to clipboard.");
  });

  document.getElementById("clear").addEventListener("click", () => {
    window.location.href = window.origin;
  });

  try {
    await setupMetronomeSample();
  } catch (e) {
    document.body.innerText = e.stack;
  }

  vca = audioCtx.createGain();
  vca.gain.value = DEFAULT_GAIN;
  vca.connect(audioCtx.destination);

  draw();

  startAnimationLoop();
};
